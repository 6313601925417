td {
  font-size: 13px !important;
}

.ToolbarRegular {
  min-height: 40px !important;
}

.SelectedMenu {
  border-left-width: 4px !important;
  border-left-color: red !important;
  border-left-style: solid !important;
  color: red !important;
  background-color: #F3F3F3 !important;
}

.HomePageCard:hover {
  background-color: #eeeeee !important;
  border-bottom-width: 2px !important;
  border-bottom-color: red !important;
  border-bottom-style: solid !important;
}

.HomePageCard {
  background-color: #eeeeee !important;
  border-bottom-width: 2px !important;
  border-bottom-color: #ffffff !important;
  border-bottom-style: solid !important;
}

.Widget {
  margin: 0px;
  padding: 10px;
  border-radius: 5px;
  background-color: #eeeeee;
}

.Form {
  margin: 0px;
  padding: 0px;
  border-radius: 5px;
  background-color: #ffffff;
  width: 100%;
  display: flex;
}

@media only screen and (min-width: 1224px) {
  .FormDetails {
    padding: 20px !important;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 1223px) {
  .FormDetails {
    padding: 10px !important;
    border-radius: 5px;
  }
}

.FormHeader {
  height: 40px !important;
  padding: 5px;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(201, 216, 241, 0.15) 30%, rgba(201, 216, 241, 0.25) 60%, rgba(201, 216, 241, 0.4) 100%);
}

.BoldFormHeader {
  height: 40px !important;
  padding: 5px;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #0288D1 !important;
  color: white !important;
}


.FormFooter {
  height: 50px !important;
  padding: 0px;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background: linear-gradient(to top, #ffffff 0%, #ecf0f4 100%, #b3bfc9 100%, #e8e9ff 100%, #0288D1 100%);   */
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(201, 216, 241, 0.15) 30%, rgba(201, 216, 241, 0.25) 60%, rgba(201, 216, 241, 0.4) 100%);
}

.FormHeaderOrFooterDetails {
  text-align: center;
  width: 100%;
  font-size: 16px !important;
  font-weight: bold;
}

.BoldFormHeaderDetails {
  text-align: center;
  width: 100%;
  font-size: 24px !important;
  font-weight: bold;
}

.FormFieldFrame {
  border-radius: 5px;
  color: #0288D1;
  font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 11px;
  border-color: #cccccc;
  border-width: 1px;
}

.FieldFrame {
  border-radius: 5px;
  border-color: #cccccc;
  border-width: 1px;
}

.CheckBoxChecked {
  color: red !important;
}

.RadioButtonLabelImpactRating {
  font-size: 13px !important;
  color: #000000 !important;
}

.HorizontalOverFlowContainer {
  overflow-x: hidden;
  width: -webkit-fill-available;
}

.PaneDividerWrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* https://www.cssportal.com/css-ribbon-generator/ */
.box {
  width: 200px;
  height: 300px;
  position: relative;
  border: 1px solid #BBB;
  background: #EEE;
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 120px;
  height: 120px;
  text-align: right;
}

.ribbon span {
  font-size: 9px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 150px;
  display: block;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 40px;
  right: -25px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #999999;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #999999;
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #999999;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #999999;
}

.PaginationUl {
  display: inline-flex !important
}

.CardContentRoot {
  padding: 5px !important;
  background-color: #f3f3f3;
}

.RequestStatusGrid{
 padding-left: 0px !important;
}

@media only screen and (min-width: 1224px) {
  #CreateEditRequestContainer{
    padding-right: 10px !important;
    border-right: 1px solid #0288D1;
  }

  #RequestStatusContainer {
    padding-left: 10px !important;
  }
}

@media only screen and (max-width: 1223px) {
  #RequestStatusContainer {
    margin-top: 10px !important;
    padding-top: 10px !important;
    border-top: 1px solid #0288D1;
  }
}